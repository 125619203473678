<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">Vservtecnology</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold text-primary" title-tag="h2">
            Welcome to Vservtecnology
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>


          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- Username -->
              <b-form-group label="Username" label-for="login-user">
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  vid="user"
                  rules="required"
                >
                  <b-form-input
                    id="login-user"
                    v-model="username"
                    :state="errors.length > 0 ? false : null"
                    name="login-user"
                    placeholder="user"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid || isLoading"
              >
                
              <b-spinner v-if="isLoading" small  label="Spinning"></b-spinner> 
               <span v-else>Sign in </span>
              </b-button>
            </b-form>
          </validation-observer>


        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BSpinner
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import AuthService from "@/services/auth";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BSpinner
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      username: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),

      // validation rules
      required,    
      isLoading:false
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    async login() {
      const isSuccess = await this.$refs.loginForm.validate();
      if (isSuccess) {
        try {
          this.isLoading = true
          const result = await AuthService.Login({
            username: this.username,
            password: this.password,
          });

          if (result.data.status === "success") {          
            const { token } = result.data;
            const resUserData = result.data.userData
            const userData = {
              ability: [{ action: "manage", subject: "all" }],
              role: resUserData.roleName || "Unknown",
              ...resUserData,
            };
            store.commit('user/UPDATE_USER_PERMISSION', resUserData)

           
            useJwt.setToken(token);
            localStorage.setItem("userData", JSON.stringify(userData));
            this.$ability.update([{ action: "manage", subject: "all" }]);

            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `สวัสดี ${userData.firstname} ${userData.lastname}`,
                icon: "CheckIcon",
                variant: "success",
                text: `คุณได้เข้าสู่ระบบเรียบร้อย`,
              },
            });

            this.isLoading = false
            this.$router.push({ path: "/ticket/inprogress" });
          }else{
            this.isLoading = false 
            this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `เข้าสู่ระบบผิดพลาด`,
              icon: "XIcon",
              variant: "danger",
              text: `${result.data.message || "กรุณาเข้าสู่ระบบใหม่อีกครั้ง"}  `,
            },
          });
           
        }
        } catch (error) {
          this.isLoading = false 
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `เข้าสู่ระบบผิดพลาด`,
              icon: "XIcon",
              variant: "danger",
              text: `กรุณาเข้าสู่ระบบใหม่อีกครั้ง `,
            },
          });
        }
      }

    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
